import React from 'react'
import ReactDOM from 'react-dom'
import {
    AppWithState,
    createLocales,
} from '@toway/admin'
import catalog from './locales/catalog'

const locales = createLocales('nl', catalog)

ReactDOM.render(
    <React.StrictMode>
        <AppWithState
            locales={locales}
        />
    </React.StrictMode>,
    document.getElementById('root')
)
