/* eslint-disable import/extensions */
import * as constants from '@toway/constants'
import enMessages from './en/messages.js'
import nlMessages from './nl/messages.js'

const {
    LANGUAGE_EN: EN,
    LANGUAGE_NL: NL,
} = constants
const catalog = [{
    code: EN,
    messages: enMessages
}, {
    code: NL,
    messages: nlMessages
}]
export default catalog
