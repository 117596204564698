import { parseISO, format, addYears as addYears$1 } from 'date-fns';
import { parsePhoneNumber as parsePhoneNumber$1 } from 'libphonenumber-js';

var VAT_MASKS = {
  BE: ['B', 'E', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
  NL: ['N', 'L', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, 'B', /\d/, /\d/]
};
var VAT_REGEX_STRINGS$1 = {
  BE: /^(BE) (\d{10}$)/,
  NL: /^(NL) (\d{9}B\d{2}$)/
};
var POSTAL_CODE_MASKS = {
  BE: [/[1-9]/, /\d/, /\d/, /\d/],
  NL: [/[1-9]/, /\d/, /\d/, /\d/, ' ', /[A-Z]/i, /[A-Z]/i]
};
var EUR = '€';
var ORDER_STATUS_UNKNOWN = 'UNKNOWN';
var ORDER_STATUS_CREATED = 'CREATED';
var ORDER_STATUS_PAID = 'PAID';
var ORDER_STATUS_SHIPPED = 'SHIPPED';
var ORDER_STATUS_CANCELED = 'CANCELED';
var LANGUAGE_NL = 'nl';
var LANGUAGE_EN = 'en';

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    if (enumerableOnly) symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    });
    keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread2(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};

    if (i % 2) {
      ownKeys(Object(source), true).forEach(function (key) {
        _defineProperty(target, key, source[key]);
      });
    } else if (Object.getOwnPropertyDescriptors) {
      Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
      ownKeys(Object(source)).forEach(function (key) {
        Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
      });
    }
  }

  return target;
}

function _slicedToArray(arr, i) {
  return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest();
}

function _toConsumableArray(arr) {
  return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread();
}

function _arrayWithoutHoles(arr) {
  if (Array.isArray(arr)) return _arrayLikeToArray(arr);
}

function _arrayWithHoles(arr) {
  if (Array.isArray(arr)) return arr;
}

function _iterableToArray(iter) {
  if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter);
}

function _iterableToArrayLimit(arr, i) {
  if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return;
  var _arr = [];
  var _n = true;
  var _d = false;
  var _e = undefined;

  try {
    for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
      _arr.push(_s.value);

      if (i && _arr.length === i) break;
    }
  } catch (err) {
    _d = true;
    _e = err;
  } finally {
    try {
      if (!_n && _i["return"] != null) _i["return"]();
    } finally {
      if (_d) throw _e;
    }
  }

  return _arr;
}

function _unsupportedIterableToArray(o, minLen) {
  if (!o) return;
  if (typeof o === "string") return _arrayLikeToArray(o, minLen);
  var n = Object.prototype.toString.call(o).slice(8, -1);
  if (n === "Object" && o.constructor) n = o.constructor.name;
  if (n === "Map" || n === "Set") return Array.from(o);
  if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen);
}

function _arrayLikeToArray(arr, len) {
  if (len == null || len > arr.length) len = arr.length;

  for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i];

  return arr2;
}

function _nonIterableSpread() {
  throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}

function _nonIterableRest() {
  throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}

var FORMAT_DATE_STRING = 'dd-MM-yyyy';
var FORMAT_TIME_STRING = 'HH:mm';
var getNow = function getNow() {
  return new Date();
};
var parseTime = function parseTime(timeString) {
  var dateTime = new Date();

  var _timeString$split = timeString.split(':'),
      _timeString$split2 = _slicedToArray(_timeString$split, 2),
      hours = _timeString$split2[0],
      minutes = _timeString$split2[1];

  dateTime.setHours(Number(hours));
  dateTime.setMinutes(Number(minutes));
  dateTime.setSeconds(0);
  return dateTime;
};
var parseDate = function parseDate(dateString) {
  return parseISO(dateString);
};
var formatDate = function formatDate(date) {
  var formatString = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : FORMAT_DATE_STRING;
  var options = arguments.length > 2 ? arguments[2] : undefined;
  return format(date, formatString, options);
};
var formatTime = function formatTime(date) {
  var formatString = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : FORMAT_TIME_STRING;
  var options = arguments.length > 2 ? arguments[2] : undefined;
  return format(date, formatString, options);
};
var formatDateString = function formatDateString(isoString, formatString, options) {
  return formatDate(parseDate(isoString), formatString, options);
};
var formatTimeString = function formatTimeString(isoString, formatString, options) {
  return formatTime(parseDate(isoString), formatString, options);
};
var DEFAULT_DATE_TIME_FORMAT = 'd MMMM HH:mm"';
var addYears = function addYears(date, amount) {
  return addYears$1(date, amount);
};

var connectionToCollection = function connectionToCollection() {
  var connection = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
    edges: []
  };
  return connection.edges.map(function (edge) {
    return edge.node;
  });
};
var nestedConnectionToCollection = function nestedConnectionToCollection(key) {
  return function (entity) {
    return _objectSpread2(_objectSpread2({}, entity), {}, _defineProperty({}, key, connectionToCollection(entity[key])));
  };
};
var mergeQueryResults = function mergeQueryResults(previousResult, fetchMoreResult, path) {
  var _fetchMoreResult$path = fetchMoreResult[path],
      newEdges = _fetchMoreResult$path.edges,
      totalCount = _fetchMoreResult$path.totalCount,
      pageInfo = _fetchMoreResult$path.pageInfo;
  return newEdges.length ? _defineProperty({}, path, {
    // eslint-disable-next-line no-underscore-dangle
    __typename: previousResult[path].__typename,
    edges: [].concat(_toConsumableArray(previousResult[path].edges), _toConsumableArray(newEdges)),
    pageInfo: pageInfo,
    totalCount: totalCount
  }) : previousResult;
};

var priceFormatter = function priceFormatter(price) {
  if (price === null || typeof price === 'undefined') {
    return '';
  }

  return "".concat(price / 100).replace('.', ',');
};
var priceHelper = function priceHelper() {
  var price = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
  var currency = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : EUR;
  return "".concat(currency, " ").concat((price / 100).toFixed(2)).replace('.', ',');
};

var createStringFromList = function createStringFromList(list) {
  if (!list || !list.length) return;
  var string = '';
  list.forEach(function (item) {
    string = "".concat(string || '').concat(string ? ', ' : '', " ").concat(item);
  });
  return string;
}; // String utils
//
// resources:
//  -- mout, https://github.com/mout/mout/tree/master/src/string

/**
* "Safer" String.toLowerCase()
*/

var lowerCase = function lowerCase(str) {
  return str.toLowerCase();
};
/**
* "Safer" String.toUpperCase()
*/

var upperCase = function upperCase(str) {
  return str.toUpperCase();
};
/**
* Convert string to camelCase text.
*/

var camelCase = function camelCase(str) {
  str = replaceAccents(str);
  str = removeNonWord(str).replace(/-/g, ' ') // convert all hyphens to spaces
  .replace(/\s[a-z]/g, upperCase) // convert first char of each word to UPPERCASE
  .replace(/\s+/g, '') // remove spaces
  .replace(/^[A-Z]/g, lowerCase); // convert first char to lowercase

  return str;
};
/**
* Add space between camelCase text.
*/

var unCamelCase = function unCamelCase(str) {
  str = str.replace(/([a-z\xE0-\xFF])([A-Z\xC0\xDF])/g, '$1 $2');
  str = str.toLowerCase(); // add space between camelCase text

  return str;
};
/**
* UPPERCASE first char of each word.
*/

var properCase = function properCase(str) {
  return lowerCase(str).replace(/^\w|\s\w/g, upperCase);
};
/**
* camelCase + UPPERCASE first char
*/

var pascalCase = function pascalCase(str) {
  return camelCase(str).replace(/^[a-z]/, upperCase);
};
var normalizeLineBreaks = function normalizeLineBreaks(str, lineEnd) {
  lineEnd = lineEnd || 'n';
  return str.replace(/rn/g, lineEnd) // DOS
  .replace(/r/g, lineEnd) // Mac
  .replace(/n/g, lineEnd); // Unix
};
/**
* UPPERCASE first char of each sentence and lowercase other chars.
*/

var sentenceCase = function sentenceCase(str) {
  return lowerCase(str).replace(/(^\w)|\.\s+(\w)/gm, upperCase);
};
/**
* Convert to lower case, remove accents, remove non-word chars and
* replace spaces with the specified delimeter.
* Does not split camelCase text.
*/

var slugify = function slugify(str, delimeter) {
  if (delimeter == null) {
    delimeter = '-';
  }

  str = replaceAccents(str);
  str = removeNonWord(str);
  str = str.trim().toLowerCase() // should come after removeNonWord
  .replace(/ +/g, delimeter) // replace spaces with delimeter
  .replace(/\-\-+/g, delimeter) // replace extra delimiters
  .toLowerCase();
  return str;
};
/**
* Replaces spaces with hyphens, split camelCase text, remove non-word chars,
* remove accents and convert to lower case.
*/

var hyphenate = function hyphenate(str) {
  str = unCamelCase(str);
  return slugify(str, '-');
};
/**
* Replaces hyphens with spaces. (only hyphens between word chars)
*/

var unhyphenate = function unhyphenate(str) {
  return str.replace(/(\w)(-)(\w)/g, '$1 $3');
};
/**
* Replaces spaces with underscores, split camelCase text, remove
* non-word chars, remove accents and convert to lower case.
*/

var underscore = function underscore(str) {
  str = unCamelCase(str);
  return slugify(str, '_');
};
/**
* Remove non-word chars.
*/

var removeNonWord = function removeNonWord(str) {
  return str.replace(/[^0-9a-zA-Z\xC0-\xFF -]/g, '');
};
/**
* Replaces all accented chars with regular ones
*/

var replaceAccents = function replaceAccents(str) {
  // verifies if the String has accents and replace them
  if (str.search(/[\xC0-\xFF]/g) > -1) {
    str = str.replace(/[\xC0-\xC5]/g, 'A').replace(/[\xC6]/g, 'AE').replace(/[\xC7]/g, 'C').replace(/[\xC8-\xCB]/g, 'E').replace(/[\xCC-\xCF]/g, 'I').replace(/[\xD0]/g, 'D').replace(/[\xD1]/g, 'N').replace(/[\xD2-\xD6\xD8]/g, 'O').replace(/[\xD9-\xDC]/g, 'U').replace(/[\xDD]/g, 'Y').replace(/[\xDE]/g, 'P').replace(/[\xE0-\xE5]/g, 'a').replace(/[\xE6]/g, 'ae').replace(/[\xE7]/g, 'c').replace(/[\xE8-\xEB]/g, 'e').replace(/[\xEC-\xEF]/g, 'i').replace(/[\xF1]/g, 'n').replace(/[\xF2-\xF6\xF8]/g, 'o').replace(/[\xF9-\xFC]/g, 'u').replace(/[\xFE]/g, 'p').replace(/[\xFD\xFF]/g, 'y');
  }

  return str;
};
/**
* Searches for a given substring
*/

var contains = function contains(str, substring, fromIndex) {
  return str.indexOf(substring, fromIndex) !== -1;
};
/**
* Truncate string at full words.
*/

var crop = function crop(str, maxChars, append) {
  return truncate(str, maxChars, append, true);
};
/**
* Escape RegExp string chars.
*/

var escapeRegExp = function escapeRegExp(str) {
  var ESCAPE_CHARS = /[\\.+*?^$[\](){}/'#]/g;
  return str.replace(ESCAPE_CHARS, '\\$&');
};
/**
* Escapes a string for insertion into HTML.
*/

var escapeHtml = function escapeHtml(str) {
  str = str.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/'/g, '&#39;').replace(/"/g, '&quot;');
  return str;
};
/**
* Unescapes HTML special chars
*/

var unescapeHtml = function unescapeHtml(str) {
  str = str.replace(/&amp;/g, '&').replace(/&lt;/g, '<').replace(/&gt;/g, '>').replace(/&#39;/g, "'").replace(/&quot;/g, '"');
  return str;
};
/**
* Escape string into unicode sequences
*/

var escapeUnicode = function escapeUnicode(str, shouldEscapePrintable) {
  return str.replace(/[\s\S]/g, function (ch) {
    // skip printable ASCII chars if we should not escape them
    if (!shouldEscapePrintable && /[\x20-\x7E]/.test(ch)) {
      return ch;
    } // we use "000" and slice(-4) for brevity, need to pad zeros,
    // unicode escape always have 4 chars after "\u"


    return "\\u".concat("000".concat(ch.charCodeAt(0).toString(16)).slice(-4));
  });
};
/**
* Remove HTML tags from string.
*/

var stripHtmlTags = function stripHtmlTags(str) {
  return str.replace(/<[^>]*>/g, '');
};
/**
* Remove non-printable ASCII chars
*/

var removeNonASCII = function removeNonASCII(str) {
  return str.replace(/[^\x20-\x7E]/g, '');
};
/**
* String interpolation
*/

var interpolate = function interpolate(template, replacements, syntax) {
  var stache = /\{\{(\w+)\}\}/g; // mustache-like

  var replaceFn = function replaceFn(match, prop) {
    return prop in replacements ? replacements[prop] : '';
  };

  return template.replace(syntax || stache, replaceFn);
};
/**
* Pad string with `char` if its' length is smaller than `minLen`
*/

var rpad = function rpad(str, minLen, ch) {
  ch = ch || ' ';
  return str.length < minLen ? str + repeat(ch, minLen - str.length) : str;
};
/**
* Pad string with `char` if its' length is smaller than `minLen`
*/

var lpad = function lpad(str, minLen, ch) {
  ch = ch || ' ';
  return str.length < minLen ? repeat(ch, minLen - str.length) + str : str;
};
/**
* Repeat string n times
*/

var repeat = function repeat(str, n) {
  return new Array(n + 1).join(str);
};
/**
* Limit number of chars.
*/

var truncate = function truncate(str, maxChars, append, onlyFullWords) {
  append = append || '...';
  maxChars = onlyFullWords ? maxChars + 1 : maxChars;
  str = trim(str);

  if (str.length <= maxChars) {
    return str;
  }

  str = str.substr(0, maxChars - append.length); // crop at last space or remove trailing whitespace

  str = onlyFullWords ? str.substr(0, str.lastIndexOf(' ')) : trim(str);
  return str + append;
};
var WHITE_SPACES = [' ', '\n', '\r', '\t', '\f', '\v', "\xA0", "\u1680", "\u180E", "\u2000", "\u2001", "\u2002", "\u2003", "\u2004", "\u2005", "\u2006", "\u2007", "\u2008", "\u2009", "\u200A", "\u2028", "\u2029", "\u202F", "\u205F", "\u3000"];
/**
* Remove chars from beginning of string.
*/

var ltrim = function ltrim(str, chars) {
  chars = chars || WHITE_SPACES;
  var start = 0;
  var len = str.length;
  var charLen = chars.length;
  var found = true;
  var i;
  var c;

  while (found && start < len) {
    found = false;
    i = -1;
    c = str.charAt(start);

    while (++i < charLen) {
      if (c === chars[i]) {
        found = true;
        start++;
        break;
      }
    }
  }

  return start >= len ? '' : str.substr(start, len);
};
/**
* Remove chars from end of string.
*/

var rtrim = function rtrim(str, chars) {
  chars = chars || WHITE_SPACES;
  var end = str.length - 1;
  var charLen = chars.length;
  var found = true;
  var i;
  var c;

  while (found && end >= 0) {
    found = false;
    i = -1;
    c = str.charAt(end);

    while (++i < charLen) {
      if (c === chars[i]) {
        found = true;
        end--;
        break;
      }
    }
  }

  return end >= 0 ? str.substring(0, end + 1) : '';
};
/**
* Remove white-spaces from beginning and end of string.
*/

var trim = function trim(str, chars) {
  chars = chars || WHITE_SPACES;
  return ltrim(rtrim(str, chars), chars);
};
/**
* Capture all capital letters following a word boundary (in case the
    * input is in all caps)
    */

var abbreviate = function abbreviate(str) {
  return str.match(/\b([A-Z])/g).join('');
};
var capitalize = function capitalize(str) {
  return str[0].toUpperCase() + str.slice(1);
};
/**
* Split array into comma separated string
*/

var arrayToCommaSeparatedString = function arrayToCommaSeparatedString(arr) {
  return arr.toString().replace(/,/g, ', ');
};

var pixelsToRem = function pixelsToRem(item) {
  return item === 0 ? 0 : "".concat(item / 10, "rem");
};
var where = function where() {
  for (var _len = arguments.length, keys = new Array(_len), _key = 0; _key < _len; _key++) {
    keys[_key] = arguments[_key];
  }

  return function () {
    for (var _len2 = arguments.length, values = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
      values[_key2] = arguments[_key2];
    }

    return function (obj) {
      return keys.every(function (key) {
        return key.includes('!') ? !values.includes(obj[key.replace('!', '')]) : values.includes(obj[key]);
      });
    };
  };
};
var whereIsNot = function whereIsNot(key) {
  return function (value) {
    return function (obj) {
      return obj[key] !== value;
    };
  };
};
var whereId = where('id');
var whereIsNotId = whereIsNot('id');
var whereIsNotRoute = whereIsNot('route');
var whereVariant = where('variant');
var whereValue = where('value');
var whereFullCode = where('fullCode');
var parsePhoneNumber = function parsePhoneNumber(phone, country) {
  return parsePhoneNumber$1(phone, country).formatInternational();
};

var VAT_REGEX_STRINGS = {
  BE: /^(BE) (\d{10}$)/,
  NL: /^(NL) (\d{9}B\d{2}$)/
};
var emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
var isRequired = function isRequired(value) {
  return typeof value !== 'undefined' && value !== null && value !== '' && value.length !== 0;
};
var isNumber = function isNumber(value) {
  return typeof value === 'number' && !Number.isNaN(value);
};
var isGreaterThan = function isGreaterThan(max) {
  return function (value) {
    return value < max;
  };
};
var isGreaterOrEqualThan = function isGreaterOrEqualThan(max) {
  return function (value) {
    return value >= max;
  };
};
var isLessThan = function isLessThan(max) {
  return function (value) {
    return value < max;
  };
};
var isLessOrEqualThan = function isLessOrEqualThan(max) {
  return function (value) {
    return value <= max;
  };
};
var isValidEmail = function isValidEmail(value) {
  return emailRegex.test(value);
};
var isValidVatForCountry = function isValidVatForCountry(vatNumber, countryCode) {
  if (vatNumber) {
    var regex = VAT_REGEX_STRINGS[countryCode];

    if (typeof regex !== 'undefined') {
      return regex.test(vatNumber);
    }
  }

  return true;
};

export { DEFAULT_DATE_TIME_FORMAT, EUR, LANGUAGE_EN, LANGUAGE_NL, ORDER_STATUS_CANCELED, ORDER_STATUS_CREATED, ORDER_STATUS_PAID, ORDER_STATUS_SHIPPED, ORDER_STATUS_UNKNOWN, POSTAL_CODE_MASKS, VAT_MASKS, VAT_REGEX_STRINGS$1 as VAT_REGEX_STRINGS, abbreviate, addYears, arrayToCommaSeparatedString, camelCase, capitalize, connectionToCollection, contains, createStringFromList, crop, escapeHtml, escapeRegExp, escapeUnicode, formatDate, formatDateString, formatTime, formatTimeString, getNow, hyphenate, interpolate, isGreaterOrEqualThan, isGreaterThan, isLessOrEqualThan, isLessThan, isNumber, isRequired, isValidEmail, isValidVatForCountry, lowerCase, lpad, ltrim, mergeQueryResults, nestedConnectionToCollection, normalizeLineBreaks, parseDate, parsePhoneNumber, parseTime, pascalCase, pixelsToRem, priceFormatter, priceHelper, properCase, removeNonASCII, removeNonWord, repeat, replaceAccents, rpad, rtrim, sentenceCase, slugify, stripHtmlTags, trim, truncate, unCamelCase, underscore, unescapeHtml, unhyphenate, upperCase, where, whereFullCode, whereId, whereIsNot, whereIsNotId, whereIsNotRoute, whereValue, whereVariant };
